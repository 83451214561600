@import '_mixin';

.berekeBank {
  & .cols {
    @include flex_align_justify(center, space-between);

    & .col {
      width: 48%;
    }
  }
}
