@import '_mixin';
@import '_animation';

.ProductsForm {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  .ProductItem {
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #f1f2f4;
    margin-bottom: 25px;

    & .bank_info {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      & .logo {
        height: 40px;
        width: 40px;
        border: 1px solid #e9eaec;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }

      & p {
        color: #111827;
        font-weight: 700;
        line-height: 150%;
        font-size: 16px;
      }
    }

    & .title {
      display: flex;
      align-items: center;
      //justify-content: space-between;
      margin-top: 20px;
      border-bottom: 1px solid #f1f2f4;
      padding-bottom: 10px;

      & .presentTitle {
        width: 220px;
        font-weight: bold;
        padding: 0 11px;
      }

      & .title_inner_root {
        display: flex;
        align-items: center;
        width: 280px;
      }

      & .ant-switch-checked {
        background: #1f9aff !important;
      }

      & div {
        & .ant-select {
          & .ant-select-selector {
            width: 230px;
            color: #111827;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      & p {
        color: #111827;
        font-weight: 600;
        line-height: 150%;
        font-size: 14px;
      }
    }

    & .pagination {
      & .PaginationItem {
        & .ant-switch {
          margin-right: 16px;

          &-checked {
            background-color: #323b49;

            &:hover {
              background: #111827 !important;
            }
          }
        }

        & > div {
          height: 80px;
          padding: 16px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #f1f2f4;

          & .ant-select {
            width: 30%;
            & .ant-select-selector {
              border: none;
            }
          }

          & .switch_block {
            display: flex;
            align-items: center;
            width: 30%;

            & p {
              font-size: 14px;
              font-weight: 500;
              line-height: 140%;
              color: #111827;
            }
          }
        }
      }
    }
  }

  z-index: 1;

  & .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border: 1px solid #1f9aff;

    &::after {
      width: 8px;
      height: 12px;
    }
  }
  & .ant-radio,
  & .ant-checkbox {
    font-size: 20px;
  }

  &
    :where(.css-dev-only-do-not-override-nllxry).ant-checkbox-checked
    .ant-checkbox-inner {
    background-color: #1f9aff !important;
    border: 1px solid #1f9aff !important;
  }

  & form {
    position: absolute;
    width: 780px;
    height: 100vh;
    background-color: white;
    right: 0;
    z-index: 5;
    overflow-y: auto;
    padding: 32px 32px 75px 32px;
    text-align: left;

    & .close_btn {
      position: absolute;
      height: 30px;
      width: 35px;
      right: 30px;
      top: 20px;
      background: none;
      border: none;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & .add_btn {
      @include flex_align_justify(center, normal);
      @include size_weight_height_color(14px, 400, 140%, #111827);
      cursor: pointer;
      position: relative;
      margin: 24px 0;

      &::after {
        content: '';
        display: inline-block;
        background: url('../media/icons/plus.png') no-repeat 100% 100%;
        height: 15px;
        width: 15px;
        margin-left: 10px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 19%;
        height: 1px;
        top: 18px;
        border-bottom: 1px dotted black;
      }
    }
  }
}

.init {
  display: none;
}

.close {
  animation: fadeOut 0.2s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}

.open {
  animation: fadeIn 0.2s ease-out;
  animation-fill-mode: forwards;
}

.checkbox_root {
  @include flex_align_justify(center, space-between);
  height: 65px;
  width: 716px;
  position: fixed;
  bottom: 0;
  background: #fff;

  & .checkbox_root_paragraph {
    @include size_weight_height_color(14px, 500, 140%, #111827);
  }

  & button {
    @include flex_align_justify(center, center);
    height: 48px;
    padding: 16px 24px;
    gap: 8px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
  }

  & .checkbox_root_buttons {
    display: flex;
  }

  & .save_button {
    margin-left: 16px;
    border: none;
    background: #1f9aff;
    color: #fff;
  }

  .cancel_button {
    border-width: 1px;
    background: #fff;
  }
}
