@import '_mixin';

.NewLeasing {
  margin: -20px;

  & .container {
    padding-top: 10px;
    height: calc(100vh - 110px);
    margin: 0 auto;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    & .content {
      width: 100%;
      margin: 0 auto;
      max-width: 1200px;
      background-color: white;
      border-radius: 10px;
      padding: 24px;

      h1 {
        color: black;
        margin-bottom: 10px;
        font-size: 17px;
        border: none;
        font-weight: bold;
        text-align: start;
      }

      & .NewLeasing_form {
        text-align: left;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
