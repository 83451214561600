.OfferStep {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: #fff;
  padding: 20px 25px;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;

  & .DeclinedTitle {
    color: #111827;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-align: left;

    border-bottom: none;
    padding-top: 24px;
    margin-top: 24px;
    margin-bottom: 15px;
    border-top: 1px solid #e9eaec;
  }

  & .main_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    & h4 {
      font-weight: 700;
      color: #111827;
      font-size: 20px;
      height: 28px;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    & button {
      font-family: IBM Plex Sans;
      display: flex;
      padding: 10.5px 24px;
      justify-content: center;
      align-items: center;
      height: auto;
      background-color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      border-radius: 10px;
      border: 1px solid #cbd5e0;
      transition: 0.2s;
    }

    & button:hover {
      background-color: #eff1f3;
    }

    &__filters.main_header__filters {
      padding: 8px;
    }

    & p {
      margin-left: 8px;
    }
  }

  & .Bank_title {
    font-size: 16px;
    align-self: stretch;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    max-width: 550px;
    //min-width: 350px;
    text-align: left;
    letter-spacing: 0.2px;
    color: #1f2937;
    display: flex;
    align-items: center;
  }

  & .BankItem_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    align-self: stretch;
    margin-top: 5px;
    margin-bottom: 20px;

    & .BankAccept_offer {
      font-family: IBM Plex Sans;
      white-space: nowrap;
      display: flex;
      height: 32px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 10px;
      background: #ff2e1f;
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      border: none;
      transition: 0.2s;
    }

    & .BankAccept_offer:hover {
      background: #1f2937;
    }

    & .BankAccept_show_alternative {
      width: auto;
      display: flex;
      height: 32px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid var(--greyscale-600, #687588);
      white-space: nowrap;
      background: #fff;
      transition: 0.2s;

      & img {
        width: 16px;
        height: 16px;
      }

      & p {
        font-family: IBM Plex Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
      }
    }

    & .BankAccept_show_alternative:hover {
      background: #eff1f3;
    }

    & .BankItem_logo_wrapper {
      width: 40px;
      height: 40px;
      border: 1px solid #e9eaec;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        width: 25px;
        height: 25px;
      }
    }
  }

  & .Bank_status {
    color: #111827;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    display: flex;
    align-items: center;
  }

  & .BankItem {
    display: flex;
    padding: 18px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    margin-bottom: 24px;
    background: #fff;

    & .BankItem_status {
      color: #111827;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 140%;
      display: flex;
      align-items: center;
    }
  }

  & .ext_uuid {
    position: absolute;
    padding: 5px;
    border: 1px solid #e9eaec;
    background: #fff;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 5px;
    top: -15px;
  }

  & .status_type {
    position: absolute;
    padding: 5px;
    border: 1px solid #0caf60;
    background: #fff;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 10px;
    top: -15px;
  }

  & .ext_time {
    position: relative;
    padding: 5px;
    border: 1px solid #e9eaec;
    background: #fff;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: baseline;
    justify-content: center;
    right: 10px;
    top: -38px;
    margin-left: -5px;
  }

  & .approved {
    position: relative;
    border: 1px solid #0caf60;

    & .ext_uuid {
      border: 1px solid #0caf60;
    }

    & .accepted_alternative_content {
      display: flex;
      width: 1016px;
      height: 44px;
      padding: 10px;
      align-items: center;
      gap: 8px;
      border-radius: 10px;
      margin: -5px 0 20px 0;
      background: #fafafa;
    }
  }

  & .waiting_bank_financing {
    & .ext_uuid {
      border: 1px solid #0caf60;
    }
  }

  & .declined {
    position: relative;
    border-radius: 16px;
    border: 1px solid #e03137;

    & .ext_uuid {
      border: 1px solid #e03137;
    }

    & .BankItem_status::before {
      content: ' ';
      display: block;
      width: 8px;
      height: 8px;
      background-color: #e03137;
      border-radius: 50%;
      margin-right: 10px;
    }

    & .BankDeclined_content {
      display: flex;
      width: 1016px;
      min-height: 44px;
      padding: 10px;
      align-items: flex-start;
      gap: 8px;
      border-radius: 10px;
      margin: -5px 0 20px 0;
      background: #fafafa;

      & p {
        text-align: left;
      }
    }
  }

  & .waiting {
    position: relative;
    border-radius: 16px;
    border: 1px solid #e9eaec;

    & .ant-btn {
      align-self: end;
      margin: 0 10px 10px 10px;
      border: 1px solid #111827;
      color: #111827;
      font-size: 14px;
      font-style: normal;
    }

    & .BankItem_header {
      margin-bottom: 10px;

      & .BankItem_logo_wrapper {
        width: 40px;
        height: 40px;
      }

      & p {
        white-space: nowrap;
      }

      & .BankItem_status::before {
        content: ' ';
        display: block;
        width: 8px;
        height: 8px;
        background-color: #ffd023;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    & .BankWaiting_content {
      display: flex;
      width: 1016px;
      //height: 44px;
      padding: 10px;
      align-items: flex-start;
      gap: 8px;
      border-radius: 10px;
      background: #fafafa;
    }
  }

  & .waiting_bank_financing {
    position: relative;
    border-radius: 16px;
    border: 1px solid #0caf60;

    & .ant-btn {
      align-self: end;
      margin: 0 10px 10px 10px;
      border: 1px solid #111827;
      color: #111827;
      font-size: 14px;
      font-style: normal;
    }

    & .BankItem_header {
      margin-bottom: 10px;

      & .BankItem_logo_wrapper {
        width: 40px;
        height: 40px;
      }

      & p {
        white-space: nowrap;
      }

      & .BankItem_status::before {
        content: ' ';
        display: block;
        width: 8px;
        height: 8px;
        background-color: #0caf60;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    & .BankWaiting_content {
      display: flex;
      width: 1016px;
      //height: 44px;
      padding: 10px;
      align-items: flex-start;
      gap: 8px;
      border-radius: 10px;
      background: #fafafa;
    }
  }

  & .BankItem_alternative {
    width: 1020px;
    margin-left: auto;

    & .BanksAlternative_buttons_root {
      display: flex;

      & .BankAccept_show_alternative {
        margin-right: 8px;
      }
    }
  }

  & .BankInfo_root {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    align-self: stretch;

    & .BankMain_info {
      width: 615px;
      height: 131px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      & .BankMain_info_raw {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        height: 50px;

        & .BankMain_info_block {
          display: flex;
          width: 180px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          text-align: left;

          & p {
            color: #111827;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
          }

          & h4 {
            color: #111827;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
          }

          & span {
            color: #ff2e1f;
          }
          & .BankInfo_subhead {
            color: #687588;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
          }
        }
      }
    }

    & .BankAdditional_info {
      //height: 152px;
      //display: flex;
      //flex-direction: column;
      //padding-left: 30px;
      //border-left: 1px solid #e9eaec;
      //align-items: flex-start;
      //justify-content: space-between;
      height: 130px;
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      border-left: 1px solid #e9eaec;
      align-items: flex-start;
    }

    & .BankAdditional_header {
      color: #687588;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    & .BankAdditional_info_block {
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 8px;
      background: #f8f8f8;
      margin-top: 8px;
      width: 300px;

      & p {
        color: #111827;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
}

.BankLogo_root {
  display: flex;

  & div {
    margin-right: 15px;
  }
}

.custom-collapse .ant-collapse-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ant-collapse-header-text {
  width: 100%;
}
.custom-collapse .ant-collapse-expand-icon {
  position: absolute;
  bottom: 10px; /* adjust as needed */
  right: 10px; /* adjust as needed */
}
