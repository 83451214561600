.range-picker {
  position: relative;
  display: block;

  &__label {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s ease all;
    z-index: 10;
    color: rgba(0, 0, 0, 0.6);
    pointer-events: none;
  }

  &--focused {
    & .range-picker__label {
      top: 0;
      padding: 0 6px;
      font-size: 12px;
      background-color: white;
    }

    & .range-picker__field .ant-picker-separator {
      display: block;
    }
  }

  &__field {
    width: 100%;

    &.ant-picker-outlined {
      padding: 16.5px 14px;
      border-color: #c4c4c4;
    }

    & .ant-picker-suffix {
      color: #111827;
    }

    & .ant-picker-separator {
      display: none;
      height: 1px;
      width: 4px;
      background-color: #111827;

      & > span {
        display: none;
      }
    }
  }
}
