.cell-inner {
  display: flex;
  align-items: center;
  gap: 12px;

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    border-radius: 50%;
    background-color: #f1f2f4;
  }
}
