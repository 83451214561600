@import '_mixin';

.Insurance,
.litro {
  width: 1112px;
  min-height: 600px;
  border-radius: 16px;
  background-color: #fff;
  padding: 20px 25px;
  display: inline-flex;
  flex-direction: column;

  .ant-btn-icon {
    color: #cbd5e0;
  }
  & .header {
    @include flex_align_justify(center, space-between);
    margin-bottom: 24px;

    & .title {
      text-align: left;
      & h2 {
        @include size_weight_height_color(18px, 700, 140%, #111827);
      }

      & p {
        @include size_weight_height_color(14px, 400, 140%, #687588);
      }
    }

    & .pagination {
      @include flex_align_justify(center, space-between);
      width: 182px;

      & button:first-child {
        @include flex_align_justify(center, space-between);
        height: 56px;
        width: 177px;
        padding: 16px 24px;
        border-radius: 10px;
        border: 1px solid #687588;
        background-color: white;

        & p {
          @include size_weight_height_color(16px, 600, 150%, #111827);
          margin-left: 10px;
        }

        &:hover {
          border: none;
        }
      }

      & button:last-child {
        @include flex_align_justify(center, center);
        gap: 8px;
        height: 40px;
        width: 182px;
        padding: 6px 16px;
        border-radius: 10px;
        border: none;
        background-color: #1f9aff;

        & p {
          @include size_weight_height_color(16px, 600, 150%, #fff);
        }

        &:hover {
          background: #111827;
        }
      }
    }
  }

  & .table_editor_insurance {
    @include flex_align_justify(flex-start, space-between);
    gap: 16px;
    width: 100%;
    height: 54px;

    &__select {
      width: max(22%, 160px);
      height: 100%;

      & input {
        height: 100% !important;
      }
    }

    & .ant-input-wrapper {
      height: 100%;
    }

    & .ant-input-lg {
      border-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 100%;
    }

    & .ant-input-group-addon {
      & button {
        border-left: none;
        height: 100%;

        &:hover {
          border-color: #111827;
        }
      }
    }

    & .ant-input-search-button {
      border-end-end-radius: 10px !important;
      border-start-end-radius: 10px !important;
    }

    & .ant-input-group-wrapper {
      height: 100%;

      & input:hover {
        border-color: #111827;
      }
    }

    & .ant-btn-icon {
      & span {
        color: #111827 !important;
      }
    }

    & .ant-select {
      //height: 50px;

      &:not(.ant-select-disabled):not(.ant-select-customize-input):not(
          .ant-pagination-size-changer
        ):hover
        .ant-select-selector {
        border-color: #111827 !important;
      }

      & .ant-select-selector {
        border-radius: 10px;
      }

      & span {
        color: #111827;
      }

      & .ant-select-selection-item {
        text-align: left;
      }

      & .ant-select-selection-placeholder {
        text-align: left;
      }
    }

    & .filter_btn {
      @include flex_align_justify(center, center);
      background: none;
      border-radius: 10px;
      border: 1px solid #e9eaec;
      //height: 100%;
      width: 50px;
    }
  }

  & .table {
    margin-top: 24px;
    flex: 1 0 0;

    &__shown-text {
      margin-right: 16px;
      font-weight: 500;
      font-size: 12px;
      color: #687588;
    }

    & .ant-pagination {
      margin-top: 24px;
      margin-bottom: 0;
    }

    & .ant-pagination-next,
    & .ant-pagination-prev {
      @include flex_align_justify(center, center);
      width: 32px;
      height: 32px;
      border: 1px solid #f1f2f4;
    }

    & .ant-pagination-prev {
      margin-right: 24px;
    }
    & .ant-pagination-next {
      margin-left: 24px;
    }

    & .ant-pagination-item {
      @include flex_align_justify(center, center);
      width: 32px;
      height: 32px;
      border: none;

      &-active {
        background-color: #f8f8f8;
      }

      & a {
        color: #111827;
      }
    }

    & .ant-pagination-options {
      margin-left: auto;
      height: auto;

      & input {
        height: 100% !important;
      }

      & > div {
        height: auto;
      }

      & .ant-select-selector {
        padding: 8px 10px;
        height: auto;
        border: 1px solid #f1f2f4;
      }

      & .ant-select-arrow {
        transform: rotate(180deg);
      }
    }

    & .ant-pagination-item {
      border-color: #111827;

      & a {
        color: #111827;
      }
    }

    & .ant-table-thead {
      height: 56px;

      & .ant-table-column-sorter-down {
        font-size: 10px;
      }

      & .ant-table-cell {
        @include size_weight_height_color(12px, 700, 140%, #687588);
        padding: 16px !important;
      }
      & .ant-table-column-title {
        @include size_weight_height_color(12px, 700, 140%, #687588);
      }
    }

    & .ant-table-body {
      max-height: 100% !important;
      overflow: auto !important;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
        /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(131, 131, 131, 0.21);
        /* color of the scroll thumb */
        border-radius: 20px;
      }
    }

    & .ant-table-cell {
      padding: 0 16px !important;
      height: 60px;
    }
  }
}
.insurance-table-row {
  cursor: pointer;
}

.sms-verificating {
  padding: 4px 16px;
  border-radius: 8px;
  background: rgb(2, 61, 61);
  width: 120px;
}

.draft {
  padding: 4px 16px;
  border-radius: 8px;
  background: rgb(17 24 39);
  width: 120px;
}

.waiting_finance {
  padding: 4px 16px;
  border-radius: 8px;
  background: #ffd023;
  width: 120px;
}
.financed {
  padding: 4px 16px;
  border-radius: 8px;
  background: #0caf60;
  width: 120px;
}
.error {
  padding: 4px 16px;
  border-radius: 8px;
  background: #e03137;
  width: 120px;
}

.status-text {
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: white;
}

.ant-table-thead {
  .ant-table-cell {
    color: #687588 !important;
    font-size: 12px;
  }
}
