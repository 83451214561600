* {
  box-sizing: border-box;
   margin: 0;
  font-family: 'IBM Plex Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::first-letter {
  text-transform: uppercase;
}

body {
  margin: 0 auto;
  //Избавиться после адаптивности!!!
  overflow-x: hidden;
  //Избавиться после адаптивности!!!

  & button {
    cursor: pointer;
  }

  & .ant-select {
    &:hover {
      & .ant-select-selector {
        border-color: #111827 !important;
      }
    }
  }

  & .ant-input {
    &:hover {
      border-color: #111827 !important;
    }
  }

  //& .ant-picker-input {
  //  margin-top: 10px;
  //}
  //
  //& .ant-picker-suffix {
  //  margin-top: -10px;
  //}
}

