.ClientRequestsData_root {
  padding: 0 20px;
  & .ClientRequestsData_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__create-text {
      display: flex;
      align-items: center;
      gap: 8px;

      & > span {
        font-size: 20px;
        line-height: 16px;
      }
    }

    & h2 {
      text-align: left;
      color: #111827;
      flex: 1 0 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    & .ant-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      border-radius: 5px;
      border: none;
      background-color: #1f9aff;

      &:hover {
        background-color: rgb(17, 24, 39);
      }

      & p {
        font-size: 13px;
        font-weight: 600;
        line-height: 150%;
        color: #fff;
      }
    }
  }
  & .Collapse_header {
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h2 {
      color: #111827;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }

    & P {
      color: #687588;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    & .separate_dot {
      margin: 0 8px;
    }
  }

  & .ant-collapse {
    border: none;
    background: none;
  }

  & .ant-collapse-header {
    width: 100%;
  }

  & .ant-collapse-content {
    border: none;
  }

  & .ant-collapse-item {
    margin: 16px auto !important;
    width: 100%;
    border-radius: 16px;
    border: 1px solid #f1f2f4;
  }

  & .ant-collapse-content-box {
    border-radius: 0 0 16px 16px;
    border: 1px solid #f1f2f4;
    display: flex;
    flex-direction: column;

    & button {
      background: #fafafa;
    }
  }

  & table {
    width: 100%;
  }

  & .Table_header {
    height: 56px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #f1f2f4;
    background: #fafafa;

    & th {
      text-align: start;
      padding: 16px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
  }

  & .Table_body {
    height: 56px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    & td {
      padding: 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-wrap: nowrap;
    }
  }
}
