@mixin navigation_tmp {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 180px;

  & button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: #a0aec0;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.2px;
    padding: 16px;
    width: 200px;

    & img {
      margin-right: 20px;
      filter: invert(77%) sepia(15%) saturate(322%) hue-rotate(174deg)
        brightness(88%) contrast(89%);
    }

    @content;
  }

  & .active {
    color: #fff;

    & img {
      filter: invert(29%) sepia(61%) saturate(4453%) hue-rotate(348deg)
        brightness(99%) contrast(105%);
    }
  }
}

@mixin flex_align_justify($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin size_weight_height_color($size, $weight, $height, $color) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
  color: $color;
}

@mixin show-text-animation {
  display: inline;
  animation: showText 0.4s;
}

@mixin clientInfoAfter($parrentWidth, $url, $height, $width) {
  width: $parrentWidth;

  & h2 {
    position: relative;
    &::after {
      content: ' ';
      background: url($url) no-repeat 100% 100%;
      height: $height;
      width: $width;
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }
}

@mixin expanded-state {
  width: 280px;

  & .logo_box {
    & img,
    & button img {
      @include show-text-animation;
    }
  }

  & .navigation_box {
    & button:first-child {
      width: 200px;
      transition: all 0.6s;
    }

    & p {
      @include show-text-animation;
    }
  }

  & .support_setting_box p {
    @include show-text-animation;
  }
}

@mixin collapsed-state {
  width: 90px;
  padding: 24px 15px;

  & .logo_box {
    height: 48px;
    & img {
      transform: scale(0) rotate(360deg);
      transition: 0.4s;
      position: absolute;
    }

    & button {
      background: none;
      & img {
        @include show-text-animation;
        transform: scaleX(-1);
        transition: 0.4s;
        position: relative;
      }
    }
  }

  & .navigation_box {
    width: 100%;

    & button:first-child {
      width: 56px;
      justify-content: center;
      transition: all 0.6s;
    }

    & p {
      display: none;
      transition: 0.1s;
      transform: translateY(100px) scale(0);
    }
  }

  & .support_setting_box p {
    transform: scale(0);
    transition: 0.4s;
  }
}
