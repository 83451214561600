.bread-crumbs {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  color: black;

  &__link-wrapper {
    display: contents;
  }

  &__link {
    text-decoration: none;
    color: #687588;

    & + span {
      margin: 0 4px;
      color: #687588;
    }
  }
}
