@import '_mixin';

.resetPassword {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: darkslategray;

  & .bg_image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 30%;
    background: url('../media/resetBg.jfif') no-repeat 100% 100%;

    & img {
      position: absolute;
      bottom: 50px;
      left: 50px;
    }
  }

  .ressetForm {
    @include flex_align_justify(center, space-between);
    position: absolute;
    flex-direction: column;
    width: 500px;
    height: 350px;
    right: 22%;
    top: 30%;
    border: 2px solid #2c5d63;
    border-radius: 16px;
    padding: 40px 25px;
    box-shadow: 1px 1px 17px 0px whitesmoke;

    & .ant-form-item {
      width: 100%;
      margin: 0;
    }

    & .ant-input-affix-wrapper {
      height: 45px;
    }

    & button {
      width: 100%;
      height: 40px;
    }
  }
}
