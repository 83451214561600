.offer {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  height: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 16px;

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__img {
    padding: 6px;
    width: 40px;
    height: 40px;
    border: 1px solid #e9eaec;
    border-radius: 50%;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__info {
    margin-top: 24px;
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 4px 8px;

    & + & {
      margin-top: 10px;
    }
  }

  &__info-label {
    min-width: 120px;
    font-size: 12px;
    line-height: 16.8px;
  }

  &__info-value {
    font-weight: 700;
    font-size: 14px;
    line-height: 18.2px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding-top: 16px;
  }

  &__accept {
    height: auto;
    padding-block: 8px;
    border-radius: 10px;
    font-weight: 600;
    width: min(200px, 100%);
  }
}
