@import '_mixin';

.Clients {
  max-width: 1112px;
  width: 100%;
  // padding: 20px 25px;
  display: inline-flex;
  flex-direction: column;

  & .content {
    // min-height: 500px;
    border-radius: 16px;
    background-color: #fff;
    padding: 20px 25px;

    & .header {
      @include flex_align_justify(center, space-between);
      height: 50px;
      margin-bottom: 15px;

      & .title {
        text-align: left;
        & h2 {
          @include size_weight_height_color(18px, 700, 140%, #111827);
        }

        & p {
          @include size_weight_height_color(14px, 400, 140%, #687588);
        }
      }
    }

    & .table {
      flex: 1 0 0;

      & .ant-pagination {
        margin-top: 20px;
        margin-bottom: 0;
        & .ant-select-selector {
          border: 1px solid #111827;
        }
      }

      & .ant-pagination-item {
        border-color: #111827;

        & a {
          color: #111827;
        }
      }

      & .ant-table-thead {
        height: 56px;

        & .ant-table-column-sorter-down {
          font-size: 10px;
        }

        & .ant-table-cell {
          @include size_weight_height_color(12px, 700, 140%, #687588);
          padding: 16px !important;
        }
        & .ant-table-column-title {
          @include size_weight_height_color(12px, 700, 140%, #687588);
        }
      }

      & .ant-table-body {
        max-height: calc(100vh - 320px) !important;
        // height: 500px !important;
        // max-height: 500px !important;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
          background-color: transparent;
          /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(131, 131, 131, 0.21);
          /* color of the scroll thumb */
          border-radius: 20px;
        }
      }

      & .ant-table-cell {
        padding: 0 8px !important;
        height: 60px;
      }
    }
  }
}
