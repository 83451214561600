.waiting-alert {
  display: flex;
  align-items: center;
  gap: 18px;
  position: sticky;
  bottom: 24px;
  margin-top: 64px;
  padding: 18px 20px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: black;
  background-color: #fffbe6;

  will-change: opacity;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;

  &--opened {
    opacity: 1;
    pointer-events: all;
  }

  & > svg {
    width: 24px;
    height: 24px;

    & path {
      fill: #ffd023;
    }
  }

  &__close {
    display: block;
    margin-left: auto;
    padding: 0;
    border: none;
    line-height: 0;
    background-color: transparent;
  }
}
