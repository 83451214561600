@import '_mixin';

.select_switch {
  @include flex_align_justify(center, flex-start);
  height: 100%;
  width: 100%;

  & button {
    margin-right: 10px !important;
  }

  & p {
    @include size_weight_height_color(13px, 400, 140%, #111827);
  }

  & .ant-switch {
    margin-right: 16px;

    &-checked {
      background-color: #323b49;

      &:hover {
        background: #111827 !important;
      }
    }
  }
}
