.TemplateBlock {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 340px;
  height: auto;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid #e9eaec;
  background: #fafafa;

  & .icon_wrapper {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 48px;
    border: 2px solid #fff5f5;
    background: #fff;
  }

  & h2 {
    display: block;
    color: #111827;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    max-width: 300px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & p {
    color: #687588;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
