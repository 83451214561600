.float-label {
  position: relative;
  background-color: transparent;

  &__label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 11px;
    margin: 0;
    transition: 0.2s ease all;
    z-index: 10;
    color: #687588;
  }

  &__as-placeholder {
    color: #a0aec0;

    & + input,
    & + * input {
      &::placeholder {
        color: transparent;
      }
    }
  }

  &__as-label {
    top: 4px;
    font-size: 12px !important;
    padding: 0 4px;
    margin-left: -4px;

    & + input,
    & + * input {
      padding-top: 22px !important;
      padding-bottom: 10px !important;
    }
  }

  &:has(input:is(:-webkit-autofill, :autofill)) {
    & .float-label__label {
      top: 4px;
      font-size: 12px !important;
      padding: 0 4px;
      margin-left: -4px;
      color: #687588;
    }
  }
}
