.TemplateCreateBlock {
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 340px;
  height: auto;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid #e9eaec;
  background: #fafafa;

  & .ant-btn {
    border: none;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 50%;
    border: 2px solid #fff5f5;
    background: #fff;
  }

  // & .icon_wrapper{
  //     display: flex;
  //     padding: 16px;
  //     align-items: flex-start;
  //     gap: 10px;
  //     border-radius: 48px;
  //     border: 2px solid #FFF5F5;
  //     background: #FFF;
  // }

  // & h2{
  //     display: block;
  //     color: #111827;
  //     font-size: 18px;
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: 150%;
  //     max-width: 300px;
  //     text-wrap: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  // }

  // & p{
  //     color: #687588;
  //     font-size: 14px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 150%;
  // }
}
