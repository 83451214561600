.BankMainPrintItem {
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
  background: #f8f8f8;
  height: auto;
  border-radius: 8px;
  width: 470px;
  margin: 8px auto;

  & .BankMainPrintItem_bank_info {
    display: flex;
    align-items: center;

    & .BankMainPrintItem_wrapper {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e9eaec;
      border-radius: 50%;
      margin-right: 10px;

      & img {
        height: 24px;
        width: 24px;
      }
    }
  }

  & .action_root {
    display: flex;
    align-items: center;

    & button {
      border: none;
      background: none;
      margin-right: 16px;
      transition: 0.2s;

      & img {
        width: 20px;
        height: 20px;
      }
    }
  }

  & .print_checkbox {
    margin-bottom: 12px;
  }
}

.BankMainPrintItem_bank_alternative {
  background: #f5f9ff;
}

.BorderLine_print_item {
  width: 445px;
  height: 1px;
  background: #e9eaec;
  margin: 16px auto;
}
