@import '_mixin';

%orderStep_firstStep {
  & .first_step {
    & .selects_box {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(3, minmax(100px, 245px));
      grid-template-rows: repeat(3, 50px);
      gap: 16px;

      & .ant-select {
        height: 100%;
      }

      & .select_switch {
        @include flex_align_justify(center, flex-start);
        height: 100%;
        width: 100%;

        & button {
          margin-right: 10px !important;
        }

        & p {
          @include size_weight_height_color(13px, 400, 140%, #111827);
        }

        & .ant-switch {
          margin-right: 16px;

          &-checked {
            background-color: #323b49;

            &:hover {
              background: #111827 !important;
            }
          }
        }
      }
    }

    & .stocks_bonds {
      margin-top: 28px;
      padding-top: 24px;
      border-top: 1px solid #e9eaec;

      & h2 {
        @include size_weight_height_color(16px, 600, 150%, #111827);
      }

      & .stocks_bonds_root {
        @include flex_align_justify(normal, space-between);
      }

      & .stocks_bonds_root div {
        @include flex_align_justify(center, normal);
      }

      & .stocks_bonds_root .info_icon {
        margin-left: 8px;
        cursor: pointer;
      }

      & .stocks_bonds_banks div {
        @include flex_align_justify(center, center);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #e9eaec;
        margin-left: 8px;
      }

      & .stocks_bonds_banks img {
        width: 20px;
        height: 20px;
      }

      & .switches {
        margin-top: 24px;
        display: grid;
        grid-auto-rows: 50px;
        grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
        gap: 20px;

        & .switch_block {
          @include flex_align_justify(center, space-between);
          height: 100%;
          border-radius: 10px;
          background-color: #fafafa;
          padding: 8px;

          & .switch_block_checkbox {
            display: flex;
            align-items: center;
          }

          & img {
            cursor: pointer;
          }

          & .ant-switch {
            margin-right: 16px;
            &-checked {
              background-color: #323b49;
            }
          }
        }
      }
    }
  }
}

%orderStep_secondStep {
  & .second_step {
    & h2 {
      @include size_weight_height_color(20px, 700, 140%, #111827);
    }

    & .addContacts {
      margin: 10px 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e9eaec;

      & .ant-select {
        height: 100%;
      }
    }

    & .personal_info {
      margin-top: 20px;
      padding-bottom: 40px;
      //border-bottom: 1px solid #e9eaec;
      display: grid;
      grid-template-columns: repeat(3, minmax(150px, 240px));
      //grid-template-rows: repeat(3, 55px);
      gap: 18px;

      & .custonItem:first-child {
        grid-area: 1 / 1 / 2 / 2;
      }
      & .custonItem:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
      }
      & .custonItem:nth-child(3) {
        grid-area: 2 / 1 / 3 / 2;
      }
      & .custonItem:nth-child(4) {
        grid-area: 2 / 2 / 3 / 3;
      }
      & .custonItem:nth-child(5) {
        grid-area: 2 / 3 / 3 / 4;
      }
      & .custonItem:nth-child(6) {
        grid-area: 3 / 1 / 4 / 2;
      }
      & .custonItem:nth-child(7) {
        grid-area: 3 / 2 / 4 / 3;
      }
      & .custonItem:nth-child(8) {
        grid-area: 3 / 3 / 4 / 4;
      }
      & .custonItem:nth-child(9) {
        grid-area: 4 / 1 / 5 / 2;
      }
      & .custonItem:last-child {
        grid-area: 4 / 2 / 5 / 4;
      }

      & .ant-select {
        height: 100%;
      }

      & .select_switch {
        @include flex_align_justify(center, space-between);
        height: 100%;
        width: 100%;

        & p {
          @include size_weight_height_color(15px, 400, 140%, #111827);
        }

        & .ant-switch {
          margin-right: 16px;

          &-checked {
            background-color: #323b49;

            &:hover {
              background: #111827 !important;
            }
          }
        }
      }
    }

    & .adress {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, minmax(0, auto));
      gap: 18px;
      margin-top: 20px;

      & .adress_switch {
        @include flex_align_justify(center, space-between);

        & .adress_switch_paragraph {
          @include size_weight_height_color(14px, 400, 19.6px, #111827);
        }

        & .select_switch {
          @include flex_align_justify(center, space-between);
          height: 100%;

          & p {
            @include size_weight_height_color(14px, 400, 140%, #111827);
          }

          & .ant-switch {
            margin-right: 16px;

            &-checked {
              background-color: #323b49;

              &:hover {
                background: #111827 !important;
              }
            }
          }
        }
      }
    }
  }
}

%orderStep_thirdStep {
  & .ThirdStep {
    .socialCompany_root {
      border-bottom: 1px solid #e9eaec;
      padding-bottom: 20px;
    }

    & .title {
      margin-top: 16px;
    }

    & .additional_info {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 55px);
      gap: 16px;

      & .customItem {
        &:nth-child(1n) {
          grid-area: 1 / 1 / 2 / 2;
        }
        &:nth-child(2n) {
          grid-area: 1 / 2 / 2 / 3;
        }
        &:nth-child(3n) {
          grid-area: 1 / 3 / 2 / 4;
        }
        &:nth-child(4n) {
          grid-area: 2 / 1 / 3 / 2;
        }
        &:nth-child(5n) {
          grid-area: 2 / 2 / 3 / 3;
        }
        &:nth-child(6n) {
          grid-area: 3 / 1 / 4 / 2;
        }
        &:nth-child(7n) {
          grid-area: 3 / 2 / 4 / 3;
        }
        &:nth-child(8n) {
          grid-area: 3 / 3 / 4 / 4;
        }

        & label {
          width: 90%;
        }
      }

      & .ant-select {
        height: 100%;
      }

      & .select_switch {
        @include flex_align_justify(center, space-between);
        height: 100%;
        width: 100%;

        & p {
          @include size_weight_height_color(15px, 400, 140%, #111827);
        }

        & .ant-switch {
          margin-right: 16px;

          &-checked {
            background-color: #323b49;

            &:hover {
              background: #111827 !important;
            }
          }
        }
      }
    }

    & .contactsPersons {
      padding: 24px 0;
      margin-top: 24px;
      border-top: 1px solid #e9eaec;
      border-bottom: 1px solid #e9eaec;

      & .title {
        @include flex_align_justify(center, space-between);

        & h2 {
          @include size_weight_height_color(16px, 600, 150%, #111827);
        }

        & p {
          @include size_weight_height_color(14px, 400, 140%, #111827);
          cursor: pointer;
        }
      }

      & .addContacts {
        margin: 20px 0;
        border-radius: 16px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        & .ant-select {
          height: 100%;
        }
      }
    }

    .photoDocs {
      display: grid;
      grid-template-columns: 40% 60%;
      justify-items: start;
      gap: 10px;
      margin: 24px 0;
      height: fit-content;
      align-items: start;

      & .photoUpload {
        grid-area: 1 / 1 / 4 / 2;
        width: 270px;
        height: 230px;
        padding: 24px 16px;
      }

      & .fileUpload {
        //&:nth-child(1n) { grid-area: 3 / 2 / 4 / 3; }
        //&:nth-child(2) { grid-area: 2 / 2 / 3 / 3; }
        //&:nth-child(3n) { grid-area: 1 / 2 / 2 / 3; }

        & .selected {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & .fileName {
            display: flex;
            align-items: center;

            & p {
              width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            & span {
              font-size: 14px;
              line-height: 16px;
              font-weight: 400;
              color: #a0aec0;
              margin-left: 5px;
              width: 60px;
            }
          }

          & button {
            background: none;
            border: none;
            margin-top: -5px;
          }
        }
      }
    }
  }
}
