@import '_mixin';

.eurasBank {
  & .select_switch {
    margin: 30px 0;
    & p {
      @include size_weight_height_color(14px, 400, 140%, #111827);
    }
  }

  & .cols {
    @include flex_align_justify(center, space-between);

    & .col {
      width: 48%;
    }
  }

  & .Euras_files_upload_root {
    text-align: start;

    & p {
      font-style: normal;
      font-weight: 900;
      line-height: 140%;
      margin-bottom: -5px;
      font-size: 14px;
    }

    & .field_required {
      color: #111827;
      display: flex;
      align-items: center;
    }

    & .field_required:after {
      content: '   ';
      height: 10px;
      display: block;
      margin-left: 10px;
      width: 10px;
      border-radius: 50%;
      background: red;
    }

    & .field_not_required {
      color: #808080;
      display: flex;
      align-items: center;
    }

    & .field_not_required:after {
      content: '  ';
      height: 10px;
      display: block;
      margin-left: 10px;
      width: 10px;
      border-radius: 50%;
      background: green;
    }
  }
}
