.admin-modal-line {
  margin-bottom: 10px;

  .admin-modal-label {
    margin: 0;
    font-weight: bold;
  }

}

.admin-dealers-table-row {
  cursor: pointer;
}

.admin-banks-table-row {
  cursor: pointer;;
}

.flash-log-modal-table-cell {
  cursor: pointer
}


.modal {

  .ant-form-item-label {
    padding-bottom: 0px;
    margin-bottom: -3px;
    & label {

      font-size: 11px;
      color: #A9A9A9;
    }
  }
  .ant-modal-footer {
    justify-content: end;
  }
}