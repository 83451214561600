.auth_error {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  background: #ffedec;
  margin-top: 10px;
  margin-bottom: 22px;
  align-items: center;
  justify-content: space-between;

  & div {
    display: flex;
    align-items: center;

    & p {
      margin-left: 8px;
      color: #e03137;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }

  & button {
    border: none;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 20px;
      height: 20px;
    }
  }
}
