@import '_mixin';

.financingStep {
  @include flex_align_justify(flex-start, flex-start);
  width: 100%;

  & .bank_content {
    background-color: white;
    border-radius: 16px;
    padding: 24px;
    flex: 1;

    & .upload_files {
      & .Halyk_guaranty_download {
        @include flex_align_justify(center, space-between);
        height: 60px;
        background-color: #fafafa;
        padding: 24px 16px;
        border-radius: 10px;
        margin: 15px 0;
        cursor: pointer;
        text-decoration: none;

        & div {
          @include flex_align_justify(center, flex-start);
          width: 100%;

          & p {
            @include size_weight_height_color(14px, 400, 140%, #111827);
            margin-left: 20px;
            text-align: left;
          }
        }

        & button {
          background: none;
          border: none;
        }
      }
    }

    & .sendBtn {
      width: 100%;
      height: 45px;
      border-radius: 10px;
      border: none;
      background-color: #1f9aff;
      margin-top: 50px;
      @include size_weight_height_color(14px, 600, 140%, #ffffff);
    }

    & .sendBtn:disabled {
      background-color: #f1f2f4;
      color: #a0aec0;
    }

    & .title {
      @include flex_align_justify(center, space-between);

      & h2 {
        text-align: start;
        @include size_weight_height_color(20px, 700, 140%, #111827);
      }

      & .title_pagination {
        width: 55%;
        @include flex_align_justify(center, space-around);

        & p {
          @include size_weight_height_color(14px, 400, 140%, #111827);
          cursor: pointer;
          position: relative;

          &::after {
            content: ' ';
            height: 30px;
            width: 30px;
            position: absolute;
            right: -25px;
            top: -10px;
            cursor: pointer;
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            right: 0px;
            top: 18px;
            border-bottom: 1px dotted black;
          }

          &:first-child {
            &::after {
              background: url('../media/icons/download.png') no-repeat 100% 100%;
            }
          }

          &:last-child {
            &::after {
              background: url('../media/icons/refresh.png') no-repeat 100% 100%;
            }
          }
        }
      }
    }
  }

  & .status_content {
    @include flex_align_justify(center, space-between);
    flex-direction: column;
    margin-left: 20px;
    min-width: 280px;
    max-width: 35%;

    & .info {
      background-color: white;
      border-radius: 16px;
      padding: 15px;
      width: 100%;

      & .request_financed {
        display: flex;
        height: 32px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px;
        background: #0caf60;
        color: #fff;
        text-align: right;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        margin-top: 16px;
        letter-spacing: 0.2px;
      }

      & .request_canceled {
        display: flex;
        height: 32px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px;
        background: #dcdcdc;
        border: 1px solid black;
        text-align: right;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        margin-top: 16px;
        letter-spacing: 0.2px;
      }

      & .info_content {
        @include flex_align_justify(center, flex-start);
        & img {
          width: 35px;
          height: 35px;
          padding: 3px;
          border-radius: 50%;
          border: 1px solid #e9eaec;
          margin-right: 15px;
        }

        & .text {
          text-align: left;

          & p {
            @include size_weight_height_color(14px, 400, 140%, #687588);
          }

          & h3 {
            @include size_weight_height_color(16px, 600, 150%, #1f2937);
          }
        }
      }

      & button {
        margin-top: 20px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #111827;
        padding: 8px 24px;
        background-color: white;
        @include size_weight_height_color(12px, 600, 160%, #111827);
      }

      & .Payment_link {
        margin-top: 0;
        margin-bottom: -10px;
        width: 100%;
        background-color: white;
        display: flex;
        @include size_weight_height_color(12px, 600, 160%, #111827);
        & button {
          border-radius: 10px;
          width: 20px;
          border: none;
          padding: 0;
          margin-top: 5px;
          background: none;
        }
        & p {
          margin-left: 10px;
          margin-right: 15px;
        }
      }
    }

    & .info_ts {
      background-color: white;
      border-radius: 16px;
      padding: 15px;
      width: 100%;

      & .title {
        @include flex_align_justify(center, space-between);

        & h2 {
          @include size_weight_height_color(16px, 600, 150%, #111827);
        }

        & p {
          @include size_weight_height_color(14px, 400, 140%, #111827);
          @include flex_align_justify(center, normal);
          color: #111827;
          text-align: right;
          cursor: pointer;
          position: relative;

          & img {
            margin-top: -10px;
            margin-left: 5px;
          }
          &::before {
            content: '';
            display: block;
            position: absolute;
            height: 1px;
            left: 0;
            right: 25px;
            top: 18px;
            border-bottom: 1px dotted black;
          }
        }
      }

      & .ts_content {
        text-align: left;
        height: 55px;
        border-radius: 8px;
        padding: 8px 16px;
        background-color: #f8f8f8;
        margin: 10px 0;

        & .noData {
          @include flex_align_justify(center, center);
          @include size_weight_height_color(14px, 500, 160%, #687588);
          height: 100%;
        }

        & p {
          @include size_weight_height_color(12px, 400, 160%, #687588);
        }

        & h2 {
          @include size_weight_height_color(14px, 600, 140%, #111827);
        }
      }
    }
  }
}
