@import '_animation';

.ProductsForm {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 6;

  & .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border: 1px solid #1f9aff;

    &::after {
      width: 8px;
      height: 12px;
    }
  }

  & .ant-radio,
  & .ant-checkbox {
    font-size: 20px;
  }

  &
    :where(.css-dev-only-do-not-override-nllxry).ant-checkbox-checked
    .ant-checkbox-inner {
    background-color: #1f9aff !important;
    border: 1px solid #1f9aff !important;
  }

  & form {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: min(100%, 780px);
    height: 100%;
    background-color: white;
    right: 0;
    z-index: 5;
    overflow-y: auto;
    padding: 32px 32px 0 32px;
    text-align: left;

    & .add_new_product {
      & div {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        & p {
          width: 121px;
          font-weight: 400;
          font-size: 14px;
          color: #111827;
          border-bottom: 2px dotted black;
          transition: 0.2s;
          cursor: pointer;
        }

        & p:hover {
          color: #ff2e1f;
          border-color: #ff2e1f;
        }

        & .anticon-plus {
          margin-left: 8px;
        }
      }
    }

    & .close_btn {
      position: absolute;
      height: 30px;
      width: 35px;
      right: 30px;
      top: 20px;
      background: none;
      border: none;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & .add_btn {
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      cursor: pointer;
      position: relative;
      margin: 24px 0;
      display: flex;
      align-items: center;

      &::after {
        content: '';
        display: inline-block;
        background-image: url('../media/icons/plus.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 15px;
        width: 15px;
        margin-left: 10px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 19%;
        height: 1px;
        top: 18px;
        border-bottom: 1px dotted black;
      }
    }
  }
}

.init {
  display: none;
}

.close {
  animation: fadeOut 0.2s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}

.open {
  animation: fadeIn 0.2s ease-out;
  animation-fill-mode: forwards;
}

.checkbox_root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding: 12px 0 75px;
  width: 100%;
  height: auto;
  position: sticky;
  bottom: 0;
  background: #fff;

  & .checkbox_root_paragraph {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #111827;
  }

  & button {
    display: flex;
    height: 48px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
  }

  & .checkbox_root_buttons {
    display: flex;

    & .save_button {
      margin-left: 16px;
      border: none;
      background: #1f9aff;
      color: #fff;
    }

    .cancel_button {
      border-width: 1px;
      background: #fff;
    }
  }
}
