@import '_mixin';

.CustomModal {
  & .ant-select {
    width: 100%;

    & span {
      color: #111827;
    }
  }

  & .auto_selects {
    @include flex_align_justify(center, space-between);
    flex-direction: column;

    & .ant-select {
      width: 100%;
      height: 50px;
      margin-top: 25px;
    }

    & .ant-input {
      width: 100%;
      height: 50px;
      margin-top: 25px;
    }
  }

  & .ant-modal-footer {
    @include flex_align_justify(center, center);
    margin-top: 20px;

    & button {
      width: 48%;
      height: 40px;
      border: 1px solid #687588;
      border-radius: 10px;

      & + button {
        margin-left: auto;
      }

      &:first-child {
        &:hover {
          border: none;
        }
      }

      &:last-child {
        &:hover {
          background-color: #111827 !important;
        }
      }

      & span {
        @include size_weight_height_color(14px, 600, 140%, #111827);
      }

      &.ant-btn-primary {
        background-color: #0caf60;

        & span {
          @include size_weight_height_color(14px, 600, 140%, #f1f2f4);
        }

        &:disabled {
          background-color: #f1f2f4;
          border: none;

          & span {
            @include size_weight_height_color(14px, 600, 140%, #a0aec0);
          }
        }
      }
    }

    & .ant-btn-disabled {
      background-color: #f1f2f4;
    }
  }
}

.cancel {
  & .ant-modal-header {
    @include flex_align_justify(center, center);

    & .ant-modal-title {
      @include size_weight_height_color(24px, 700, 130%, #111827);
      width: 80%;
      text-align: center;
    }
  }

  & .ant-modal-footer {
    & .cancel_btn {
      border: none;
      background-color: #1f9aff !important;
    }
  }

  & .cancel_text {
    @include size_weight_height_color(14px, 400, 140%, #1f2937);
    text-align: center;
    margin: 16px 0;
  }
}

.familyStatus {
  & .ant-modal-footer {
    & button:last-child {
      background-color: #1f9aff;
      border: none;

      & span {
        color: white;
      }
    }

    & button:last-child:hover {
      background-color: #1c86dc;
    }
  }

  margin-top: -2%;
  & .spouse_data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 55px);
    gap: 15px;

    & .customItem {
      &:nth-child(1n) {
        grid-area: 1 / 1 / 2 / 3;
      }
      &:nth-child(2n) {
        grid-area: 2 / 1 / 3 / 3;
      }
      &:nth-child(3n) {
        grid-area: 3 / 1 / 4 / 3;
      }
      &:nth-child(4n) {
        grid-area: 4 / 1 / 5 / 2;
      }
      &:nth-child(5n) {
        grid-area: 4 / 2 / 5 / 3;
      }
      &:nth-child(6n) {
        grid-area: 5 / 1 / 6 / 3;
      }
      &:nth-child(7n) {
        grid-area: 6 / 1 / 7 / 3;
      }
      &:nth-child(8n) {
        grid-area: 7 / 1 / 8 / 2;
      }
      &:nth-child(9n) {
        grid-area: 7 / 2 / 8 / 3;
      }

      & .ant-select {
        width: 100%;
        height: 100%;

        & span {
          color: #111827;
        }
      }

      & .ant-picker {
        width: 100%;
      }

      & .ant-modal-header {
        @include flex_align_justify(center, center);

        & .ant-modal-title {
          @include size_weight_height_color(24px, 400, 130%, #1f2937);
        }
      }
    }
  }
}

.address_modal {
  & .ant-modal-header {
    @include flex_align_justify(center, center);

    & .ant-modal-title {
      @include size_weight_height_color(24px, 700, 130%, #111827);
      width: 80%;
      text-align: center;
    }
  }

  & .ant-modal-footer {
    & .ant-btn:last-child {
      background-color: #e03137;
      border: none;
    }
  }

  & .address_data {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, minmax(0, auto));
    gap: 18px;

    & .customItem {
      &:nth-child(1n) {
        grid-area: 1 / 1 / 2 / 3;
      }
      &:nth-child(2n) {
        grid-area: 2 / 1 / 3 / 3;
      }
      &:nth-child(3n) {
        grid-area: 3 / 1 / 4 / 3;
      }
      &:nth-child(4n) {
        grid-area: 4 / 1 / 5 / 3;
      }
      &:nth-child(5n) {
        grid-area: 5 / 1 / 6 / 2;
      }
      &:nth-child(6n) {
        grid-area: 5 / 2 / 6 / 3;
      }

      & .ant-select {
        width: 100%;
        height: 100%;

        & span {
          color: #111827;
        }
      }

      & .ant-picker {
        width: 100%;
      }

      & .ant-modal-header {
        @include flex_align_justify(center, center);

        & .ant-modal-title {
          @include size_weight_height_color(24px, 700, 130%, #111827);
        }
      }
    }

    & .select_switch {
      @include flex_align_justify(center, flex-start);
      grid-area: 6 / 1 / 7 / 3;
      height: 100%;
      width: 100%;

      & p {
        @include size_weight_height_color(15px, 400, 140%, #111827);
      }

      & .ant-switch {
        margin-right: 16px;

        &-checked {
          background-color: #323b49;

          &:hover {
            background: #111827 !important;
          }
        }
      }
    }
  }
}

.prevClientInfo {
  margin-top: 5%;

  & .ant-modal-header {
    @include flex_align_justify(normal, center);

    & .ant-modal-title {
      @include size_weight_height_color(24px, 700, 140%, #111827);
    }
  }

  & .ant-modal-footer {
    margin-top: 30px;

    & .continue_btn {
      color: white;
      background-color: #1f9aff !important;
      border: none;

      &:disabled {
        background-color: #f1f2f4 !important;
        color: #a0aec0;
      }

      &:hover {
        background-color: #111827 !important;
      }
    }

    & .cancel_btn {
      &:hover {
        border: none;
      }
    }
  }

  & .sub_title {
    @include size_weight_height_color(16px, 400, 140%, #111827);
    text-align: center;
    margin: 16px 0;
  }

  & .customItem {
    margin-bottom: 20px;

    & .ant-select {
      width: 100%;

      & span {
        color: #111827;
      }
    }

    & .ant-picker {
      width: 100%;
    }
  }
}

.smsVerify {
  margin-top: 5%;

  & .ant-modal-header {
    @include flex_align_justify(normal, center);

    & .ant-modal-title {
      @include size_weight_height_color(24px, 700, 140%, #111827);
    }
  }

  & .telNum {
    text-align: center;
  }

  & .timer_text {
    @include size_weight_height_color(14px, 400, 140%, #687588);
    text-align: center;
  }

  & .inputs {
    @include flex_align_justify(center, space-between);
    margin: 20px auto;
    width: 60%;

    & .ant-input {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      border: 1px solid #e9eaec;
    }
  }

  & .ant-modal-footer {
    margin-top: 30px;
    justify-content: center;

    & button {
      width: 100%;
    }
  }

  & .sub_title {
    @include size_weight_height_color(14px, 400, 140%, #687588);
    text-align: center;
    margin: 16px 0;
  }
}

.resetPasModal {
  margin-top: 10%;

  & .ant-modal-header {
    @include flex_align_justify(normal, center);

    & .ant-modal-title {
      @include size_weight_height_color(24px, 700, 140%, #687588);
    }
  }

  & .ant-modal-body {
    & div {
      @include flex_align_justify(center, center);
      text-align: center;

      & h2 {
        font-size: 18px;
      }
    }
  }

  & .ant-modal-footer {
    justify-content: center;
  }
}

.instruction_modal {
  top: 30%;

  & .ant-modal-title {
    @include size_weight_height_color(29px, 700, 130%, #111827);
    text-align: center;
    margin-bottom: 32px;
    margin-top: 20px;
  }

  & .instruction_modal_content {
    & h2 {
      @include size_weight_height_color(16px, 500, 150%, #111827);
      letter-spacing: 0.2px;
      margin-bottom: 8px;
      margin-top: 16px;
    }

    & p {
      @include size_weight_height_color(14px, 400, 140%, #687588);
    }
  }
}

.editFullName {
  margin-top: 5%;

  .ant-modal-header {
    @include flex_align_justify(normal, center);

    & .ant-modal-title {
      font-size: 20px;
    }
  }

  & .customItem {
    margin-bottom: 20px;

    & .ant-select {
      width: 100%;

      & span {
        color: #111827;
      }
    }

    & .ant-picker {
      width: 100%;
    }
  }

  & .ant-modal-footer {
    & button:last-child {
      background-color: #1f9aff;
      border: none;

      &:hover {
        background-color: #1c86dc;
      }
    }
  }
}

.reportError {
  margin-top: 5%;

  .ant-modal-header {
    @include flex_align_justify(normal, center);

    & .ant-modal-title {
      font-size: 20px;
    }
  }

  & .customItem {
    margin-bottom: 20px;

    & .ant-select {
      width: 100%;

      & span {
        color: #111827;
      }
    }

    & .ant-picker {
      width: 100%;
    }
  }

  & .ant-modal-footer {
    & button:last-child {
      background-color: #1f9aff;
      border: none;

      &:hover {
        background-color: #1c86dc;
      }
    }
  }
}

.modalTS {
  margin-top: 2%;

  & .ant-modal-header {
    @include flex_align_justify(normal, center);

    & .ant-modal-title {
      font-size: 24px;
    }
  }

  & .ant-modal-footer {
    & button:last-child {
      background-color: #1f9aff;
      border: none;
    }
  }

  & .inputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 60px);
    gap: 10px;

    & .customItem {
      & .ant-select {
        height: 100%;
      }

      & .ant-picker-input {
        margin-top: 15px;
      }

      &:nth-child(1n) {
        grid-area: 1 / 1 / 2 / 3;
      }
      &:nth-child(2n) {
        grid-area: 2 / 1 / 3 / 3;
      }
      &:nth-child(3n) {
        grid-area: 3 / 1 / 4 / 3;
      }
      &:nth-child(4n) {
        grid-area: 4 / 1 / 5 / 3;
      }
      &:nth-child(5n) {
        grid-area: 5 / 1 / 6 / 3;
      }
      &:nth-child(6n) {
        grid-area: 6 / 1 / 7 / 2;
      }
      &:nth-child(7n) {
        grid-area: 6 / 2 / 7 / 3;
      }
      &:nth-child(8n) {
        grid-area: 7 / 1 / 8 / 3;
      }
      &:nth-child(9n) {
        grid-area: 8 / 1 / 9 / 3;
      }
    }
  }
}

.accept_offer_modal {
  & .ant-modal-title {
    color: #111827;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    border-bottom: none;
  }

  & .accept_default_text {
    color: #1f2937;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  & .accept_default_data {
    color: #1f2937;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  & .main_info_root {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #e9eaec;
    margin-bottom: 16px;

    & div {
      display: flex;
      flex-direction: column;

      & span {
        color: #ff2e1f;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }
  }

  & .sub_info_root {
    display: flex;
    flex-direction: column;

    & div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      & p {
        color: #687588;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        & span {
          color: #111827;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
  }

  & .monthly_payment_data {
    color: #111827;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  & .bank_header_info {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: #fafafa;

    & .logo_wrapper {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #e9eaec;
    }

    h6 {
      color: #687588;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    h5 {
      color: #1f2937;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.2px;
    }
  }

  & .ant-modal-footer {
    & button:last-child {
      background-color: #1f9aff;
      border: none;
    }
    & button:last-child:hover {
      background-color: #1c86dc;
    }
  }
}

.special_conditions_modal {
  & .ant-modal-title {
    color: #111827;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 32px;
  }

  & .special_condition_data_root:not(:last-of-type) {
    padding-bottom: 16px;
    border-bottom: 1px solid #e9eaec;
  }

  & .special_condition_data_root {
    margin-bottom: 16px;

    & div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      & h5 {
        color: #687588;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      & p {
        color: #111827;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        line-height: 140%;
      }
    }
  }
}

.print_modal {
  & .ant-modal-title {
    color: #111827;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 16px;
  }

  & .print_modal_instruction {
    color: #1f2937;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 32px;
  }

  & .ant-modal-footer {
    & button:last-child {
      background-color: #1f9aff;
      border: none;
    }
    & button:last-child:hover {
      background-color: #1c86dc;
    }
  }
}

.markModelYear {
  & .ant-modal-footer {
    & button:last-child {
      background-color: #1f9aff;
      border: none;

      & span {
        color: white;
      }
    }

    & button:last-child:hover {
      background-color: #1c86dc;
    }
  }
}

.UploadType_modal {
  .ant-btn {
    width: 100%;
    height: auto;
  }

  .screen_root {
    .ant-btn {
      margin-top: -6px;
      background: rgb(160, 174, 192);
      border: none;
      border-radius: 0px 0px 10px 10px;
    }

    .ant-btn:hover {
      background: black;
    }
  }

  & .ant-modal-footer {
    & button:last-child {
      background-color: #1f9aff;
      border: none;

      & span {
        color: white;
      }
    }

    & button:last-child:hover {
      background-color: #1c86dc;
    }
  }
}

.RedactTemplateModal {
  max-width: 1200px !important;
  width: 100% !important;

  .NewTemplateInput_root {
    margin-bottom: 10px;

    & p {
      margin-bottom: 5px;
    }
  }

  & .ant-btn {
    width: 100%;
    height: auto;
  }

  & .screen_root {
    .ant-btn {
      margin-top: -6px;
      background: rgb(160, 174, 192);
      border: none;
      border-radius: 0px 0px 10px 10px;
    }

    .ant-btn:hover {
      background: black;
    }
  }

  & .ant-modal-footer {
    & button:last-child {
      background-color: #1f9aff;
      border: none;

      & span {
        color: white;
      }
    }

    & button:last-child:hover {
      background-color: #1c86dc;
    }
  }
}

.ant-otp-lg {
  justify-content: space-evenly;
}
.ant-otp-input {
  height: 70px;
  width: 70px;
  font-weight: 800;
  font-size: 30px;
  border: 1.5px solid black;
}
