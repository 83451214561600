@import '_mixin';
@import '_slices';

.OrderStep {
  @include flex_align_justify(normal, space-between);

  & .product_info {
    text-align: left;
    background-color: white;
    height: fit-content;
    width: 80%;
    border-radius: 10px;
    padding: 24px;
    margin-bottom: 20px;

    @extend %orderStep_firstStep;
    @extend %orderStep_secondStep;
    @extend %orderStep_thirdStep;

    & .continue_btn {
      @include size_weight_height_color(14px, 600, normal, white);
      padding: 14px;
      width: 100%;
      text-align: center;
      //margin-top: 14px;
      border-radius: 10px;
      border: none;
      //padding: 16px 24px;
      background-color: #1f9aff;

      &:disabled {
        background-color: #f1f2f4;
      }
    }
  }

  & .steps {
    display: flex;
    flex-direction: column;
    min-width: 280px;
    max-width: 35%;
    margin-left: 20px;

    &__cancel {
      margin-top: 16px;
    }

    & .step {
      background-color: white;
      width: 100%;
      padding: 10px 16px;
      border-radius: 10px;
      margin-bottom: 25px;

      & .notValidFirtsItem {
        & .ant-steps-item-finish:first-child {
          & > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: #111827 !important;
          }

          & .ant-steps-item-icon {
            background-color: #fff;
            border-color: #111827 !important;
            & svg {
              display: none;
            }

            &::after {
              content: 'X';
            }

            & span {
              @include size_weight_height_color(14px, 600, normal, #111827);
            }
          }
        }
      }

      & .notValidSecondItem {
        & .ant-steps-item-finish:nth-child(2) {
          & > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: #111827 !important;
          }

          & .ant-steps-item-icon {
            background-color: #fff;
            border-color: #111827 !important;
            & svg {
              display: none;
            }

            &::after {
              content: 'X';
            }

            & span {
              @include size_weight_height_color(14px, 600, normal, #111827);
            }
          }
        }
      }

      & .notValidLastItem {
        & .ant-steps-item-finish:last-child {
          & > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: #111827 !important;
          }

          & .ant-steps-item-icon {
            background-color: #fff;
            border-color: #111827 !important;
            & svg {
              display: none;
            }

            &::after {
              content: 'X';
            }

            & span {
              @include size_weight_height_color(14px, 600, normal, #111827);
            }
          }
        }
      }

      & .ant-steps-item {
        & .ant-steps-item-container {
          & .ant-steps-item-title {
            font-weight: 500;
            line-height: 19.6px;
            color: #a0aec0 !important;
          }

          &:hover {
            & .ant-steps-item-icon {
              border-color: #111827 !important;

              & span {
                color: #111827 !important;
              }
            }

            & .ant-steps-item-title {
              color: #111827 !important;
            }
          }
        }

        &.ant-steps-item-active {
          & .ant-steps-item-title {
            color: #111827 !important;
          }
        }
      }

      & .step_count {
        height: 40px;
        padding: 8px;

        & p {
          @include size_weight_height_color(14px, 600, normal, #111827);
          text-align: left;
        }
      }

      & .progress {
        display: block;
        background-color: #cbd5e0;
        height: 1px;
        margin: 5px -16px 10px;
      }

      & .step_start::after {
        content: '';
        display: block;
        background-color: #1f9aff;
        height: 100%;
        width: 30%;
      }

      & .step_middle::after {
        content: '';
        display: block;
        background-color: #1f9aff;
        height: 100%;
        width: 60%;
      }

      & .step_finish::after {
        content: '';
        display: block;
        background-color: #1f9aff;
        height: 100%;
        width: 100%;
      }

      & > .ant-steps {
        & .ant-steps-item {
          margin-left: -8px;
          padding: 8px 10px;
          width: calc(100% + 16px);
          border-radius: 4px;

          & .ant-steps-item-container {
            display: flex;
            align-items: center;
          }

          & .ant-steps-item-icon {
            @include flex_align_justify(center, center);
            float: initial;
            margin-right: 8px;
            width: 16px;
            height: 16px;

            & span:not(:has(svg)) {
              display: none;
            }
          }

          & .ant-steps-item-tail {
            display: none !important;
          }

          & .ant-steps-item-content {
            min-height: auto !important;
          }
        }

        & .ant-steps-item-active {
          background-color: #f8f8f8;

          & .ant-steps-item-icon {
            background-color: #fff;
            border-color: #e9eaec;
          }
        }

        & .ant-steps-item-finish {
          & .ant-steps-item-container {
            &:hover {
              & .ant-steps-item-icon {
                border-color: transparent !important;
              }
            }
          }

          & > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: #111827 !important;
          }

          & .ant-steps-item-title {
            color: #111827 !important;
          }

          & .ant-steps-item-icon {
            background-color: #fff;
            border-color: transparent;

            & svg {
              color: #0caf60;
            }
            & span {
              @include size_weight_height_color(14px, 600, normal, #111827);
            }
          }
        }

        & .ant-steps-item-wait {
          & .ant-steps-item-icon {
            background-color: #fff;
            border-color: #e9eaec;

            & span {
              @include size_weight_height_color(14px, 600, normal, #a0aec0);
            }
          }
        }
      }

      & button {
        @include flex_align_justify(center, center);
        @include size_weight_height_color(14px, 600, 160%, #111827);
        width: 100%;
        border-radius: 10px;
        padding: 8px 24px;
        height: 32px;
        border: 1px solid #111827;
        background-color: white;

        &:hover {
          border: none;
        }
      }
    }

    & .personSpouse {
      background-color: white;
      border-radius: 16px;
      border: 1px solid #f8f8f8;
      padding: 8px 16px 14px;

      & .title {
        @include flex_align_justify(center, space-between);
        padding: 8px 0;

        & h2 {
          @include size_weight_height_color(16px, 600, 150%, #111827);
          height: 20px;
        }

        & p {
          @include size_weight_height_color(14px, 400, 140%, #111827);
          cursor: pointer;
          position: relative;

          &::after {
            content: '';
            display: inline-block;
            background: url('../media/icons/edit_pen_icon.png') no-repeat 100%
              100%;
            height: 20px;
            width: 20px;
            margin-left: 5px;
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 62px;
            height: 1px;
            right: 27px;
            top: 22px;
            border-bottom: 1px dotted black;
          }
        }
      }

      & .spouse_info {
        text-align: left;
        padding: 8px 16px;
        background-color: #f8f8f8;
        border-radius: 8px;

        & h2 {
          @include size_weight_height_color(14px, 500, 140%, #111827);
          margin-bottom: 5px;
        }

        & p {
          @include size_weight_height_color(12px, 400, 160%, #687588);
        }
      }
    }

    & .add_products {
      background-color: white;
      width: 100%;
      border-radius: 10px;
      padding: 10px 20px;
      max-height: 28vh;
      display: flex;
      flex-direction: column;

      & .title {
        @include flex_align_justify(center, space-between);
        margin-bottom: 14px;

        & p {
          color: #111827;
          &:first-child {
            @include size_weight_height_color(16px, 600, 150%, #111827);
            text-align: left;
            width: 50%;
          }

          &:last-child {
            @include size_weight_height_color(14px, 400, 140%, #111827);
            text-align: right;
            width: 45%;
            cursor: pointer;
            position: relative;

            &::after {
              content: '';
              display: inline-block;
              background: url('../media/icons/edit_pen_icon.png') no-repeat 100%
                100%;
              height: 20px;
              width: 20px;
              margin-left: 10px;
            }

            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 65px;
              height: 1px;
              right: 29px;
              top: 23px;
              border-bottom: 1px dotted black;
            }
          }
        }
      }

      & .products {
        display: flex;
        flex-direction: column;
        max-height: 405px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        & .product {
          @include flex_align_justify(flex-start, space-between);
          padding: 6px 12px;
          background-color: #f8f8f8;
          border-radius: 8px;
          margin-bottom: 10px;
          margin-right: 5px;

          & p {
            text-align: left;
            margin-right: 5px;
            &:first-child {
              @include size_weight_height_color(14px, 500, 140%, #111827);
            }

            &:last-child {
              @include size_weight_height_color(12px, 400, 160%, #687588);
              position: relative;
              padding-right: 20px;

              &::after {
                content: '';
                display: block;
                background: url('../media/icons/check_product_icon.png')
                  no-repeat 100% 100%;
                width: 14px;
                height: 14px;
                position: absolute;
                right: 0;
                top: 3px;
              }
            }
          }
        }
      }
    }
  }
}

.notEmpty {
  & .ant-steps-item-finish {
    & .ant-steps-item-icon {
      background-color: #fff;
      border-color: #111827 !important;
      & svg {
        display: none;
      }
      &::after {
        content: 'X';
        @include size_weight_height_color(13px, 600, normal, #111827);
      }
      & span {
        @include size_weight_height_color(14px, 600, normal, #111827);
      }
    }
  }
}
