@import '_mixin';
@import '_slices';

.ant-modal-body {
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 290px;
    justify-content: space-around;
  }
}

.ant-modal-footer {
  display: flex;
  justify-content: space-between;

  .continue-btn {
    background: #1f9aff;
    border: none;
    height: 40px;
    width: 48%;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    box-sizing: border-box;
    transition: 0.7s;
    //color:#a0aec0;
    &:disabled {
      background: #f1f2f4;
    }
    &:hover {
      background: #111827 !important;
      transition: 0.7s;
      color: #a0aec0;
    }
  }

  .cancel-btn {
    height: 40px;
    width: 48%;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #111827;
    border-radius: 10px;
    box-sizing: border-box;
    color: #111827;
    //transition: 0.2s;

    &:hover {
      border: none;
      background: white !important;
      text-decoration: none;
      color: #111827 !important;
      //transition: 0.2s;
    }
  }
}
