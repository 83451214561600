.bank-waiting-item {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  height: 100%;
  border-radius: 16px;
  border: 1px solid #e5e5e5;
  text-align: left;

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;

    & > div {
      flex: 1;
    }
  }

  &__img {
    padding: 4px;
    max-width: 40px;
    height: 40px;
    border: 1px solid #e9eaec;
    border-radius: 50%;

    & > img {
      width: 100%;
      height: 100%;
      line-height: 0;
    }
  }

  &__header-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__status-wrapper {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background-color: #e5e5e5;
    }
  }

  &__status {
    position: relative;
    padding: 1px 8px;
    width: 120px;
    z-index: 1;
    border-radius: 8px;
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: black;
    background-color: #ffd023;
  }

  &__info {
    margin-top: 20px;
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 0 6px;
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #f2f5f7;

    & + & {
      margin-top: 6px;
    }
  }

  &__info-label {
    min-width: 140px;
    font-size: 12px;
    line-height: 16px;
  }

  &__info-value {
    font-weight: 700;
    font-size: 14px;
    line-height: 18.2px;

    &--red {
      color: #ff2e1f;
    }
  }
}
