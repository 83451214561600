@import '_mixin';

.ClientRequestsDetails {
  max-width: 1200px;
  width: 100%;
  padding: 20px 25px;
  display: inline-flex;
  flex-direction: column;

  & .client_info_wrapper {
    display: flex;
    margin-bottom: 24px;

    & h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: #111827;
    }

    & div {
      padding: 8px 16px;
      border-radius: 10px;
      height: 65px;
      background-color: white;
      margin-right: 16px;
      text-align: left;

      @include size_weight_height_color(14px, 400, 140%, #687588);

      &:first-child {
        @include clientInfoAfter(240px, '../media/icons/user.png', 20px, 20px);
      }

      &:first-child {
        @include clientInfoAfter(240px, '../media/icons/user.png', 20px, 20px);
      }

      &:nth-of-type(1) {
        width: 300px;

        & h2 {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 5px;
        }
      }

      &:nth-of-type(2) {
        @include clientInfoAfter(230px, '../media/icons/phone.png', 20px, 20px);
      }

      &:nth-of-type(3) {
        width: 160px;
        gap: 4px;
      }

      &:nth-of-type(4) {
        width: 160px;
        gap: 4px;
      }
    }
  }

  & .content {
    min-height: 500px;
    border-radius: 16px;
    background-color: #fff;

    & .ant-tabs-nav-wrap {
      margin: 0 24px;
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.2px;

      & .ant-tabs-ink-bar {
        background: #1f9aff;
      }

      & .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          overflow: hidden;
          color: #1f9aff;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
