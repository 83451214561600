@import '_mixin';

.prevState {
  @include flex_align_justify(center, space-between);
  height: 30px;
  background-color: #fafafa;
  padding: 24px 16px;
  border-radius: 10px;
  margin: 15px 0;
  cursor: pointer;
  width: 100%;
  text-decoration: none;

  & div {
    @include flex_align_justify(center, flex-start);
    width: 100%;

    & p {
      @include size_weight_height_color(14px, 400, 140%, #111827);
      margin-left: 20px;
      text-align: left;
    }
  }

  & button {
    background: none;
    border: none;
  }
}

.photoDocs {
  .prevState {
    width: 100%;
    margin: 0;
  }
}
