.status_content_box {
  background-color: white;
  margin-left: 20px;
  width: 25%;
  flex-direction: column;
  height: fit-content;
  margin-top: 20px;
  padding: 24px;
  border-radius: 10px;

  .info {
    background-color: white;
    border-radius: 16px;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .request_waiting {
      display: flex;
      height: 40px;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      background: #ffc01f;
      color: #fff;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      // margin-top: 1px;
      letter-spacing: 0.2px;
    }

    & .request_financed {
      display: flex;
      height: 40px;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      background: #0caf60;
      color: #fff;
      text-align: right;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      // margin-top: 1px;
      letter-spacing: 0.2px;
    }

    & .request_canceled {
      display: flex;
      height: 40px;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      background: #ff2e1f;
      color: #fff;
      // border: 1px solid black;
      text-align: right;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      margin-top: 16px;
      letter-spacing: 0.2px;
    }

    & .request_draft {
      display: flex;
      height: 40px;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 8px;
      background: #f1f2f4;
      // color: #fff;
      // border: 1px solid black;
      text-align: right;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      margin-top: 16px;
      letter-spacing: 0.2px;
    }

    & .insurance_values {
      // margin-top: 40px;
      text-align: left;
      font-size: 11px;
      //height: 55px;
      border-radius: 8px;
      padding: 8px 16px;
      background-color: #f8f8f8;
      margin: 10px 0;
    }

    // .status_text {
    //   p {
    //     font-size: 16px;
    //     color: #333;
    //   }
    // }

    button {
      background-color: white;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #1f2937;
      }
    }
    .load-btn {
      background-color: #1f9aff;
      // text-align: center;
      width: 100%;
      border-radius: 8px;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #1f2937;
      }
    }
    .status_financed,
    .status_canceled {
      font-size: 14px;
      font-weight: bold;
    }

    .status_financed {
      color: green;
    }

    .status_canceled {
      color: red;
    }

    .file_download {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .file_item {
        font-size: 14px;
        // color: #007bff;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
