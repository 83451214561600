@import '_mixin';

.fileUpload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 60px;
  padding: 5.5px 16px;
  padding-left: 44px;
  border-radius: 10px;
  border: 1px dashed #cbd5e0;
  cursor: pointer;

  & > div {
    flex: 1;
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    background-image: url('../media/icons/file-upload-icon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 20px;
    width: 20px;
  }

  & .icon-button {
    padding: 8px;
    background: none;
    border: none;
    line-height: 0;
  }

  & input {
    display: none;
  }

  & .choose {
    margin-left: 0 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: #a0aec0;
    margin-top: 5px;
    text-align: left;
  }

  & .selected {
    display: flex;
    align-items: center;
    margin-left: 0 !important;

    & .fileName {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & p {
        font-size: 14px;
        color: #111827;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      & span {
        font-size: 12px;
        font-weight: 400;
        line-height: 160%;
        color: #a0aec0;
        width: 150px;
      }
    }

    & .btn_lg {
      margin-right: -20px;
    }
  }

  & .customPlaceHolder {
    position: static;
    margin-left: 0 !important;
    height: auto;
    color: #687588;
    font-size: 12px;
    font-weight: 400;
    line-height: 160%;
    transition: 0.2s ease-out;
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;

    &::after {
      content: '*';
      color: #e03137;
      margin-left: 5px;
    }
  }
}

.fileUpload_lastStep {
  &::after {
    display: none;
  }
}
