.insurance-second-step {
  padding: 24px;
  width: 100%;
  border-radius: 10px;
  text-align: left;
  background-color: white;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__filters {
    display: block;
    padding: 8px;
    border: 1px solid #e9eaec;
    border-radius: 10px;
    line-height: 0;
    background-color: transparent;
    transition: 0.2s;

    &:hover {
      background-color: #eff1f3;
    }
  }

  &__offers {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 36px;
    margin-top: 32px;
  }
}
