.insurance-third-step {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 24px;
  text-align: left;

  &__content {
    padding: 24px;
    border-radius: 16px;
    background-color: white;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__actions {
    display: flex;
    gap: 24px;
    margin-left: auto;
  }

  &__button {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 8px;
    padding: 0;
    border: none;
    box-shadow: none;

    & .ant-btn-icon {
      margin-inline-end: 0 !important;
    }

    & svg path {
      transition: 0.2s;
    }

    &:hover {
      & svg path {
        stroke: #4096ff;
      }
    }
  }

  &__button-text {
    line-height: 120%;
    border-bottom: 1px dashed currentColor;
  }

  &__files {
    margin-top: 8px;

    & > * {
      margin: 0;
      margin-top: 16px;
    }
  }

  &__bank-info {
    padding: 16px;
    height: max-content;
    border-radius: 16px;
    background-color: white;
  }

  &__bank {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__img {
    padding: 6px;
    width: 40px;
    height: 40px;
    border: 1px solid #e9eaec;
    border-radius: 50%;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 21px;
    color: #687588;
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__footer {
    margin-top: 24px;
  }

  &__granted {
    padding: 4px 12px;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: white;
    background-color: #0caf60;
  }

  &__cancel {
    width: 100%;
    border-radius: 10px;
    border-color: #111827;
  }
}
