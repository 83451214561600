@keyframes showText {
  0% {
    transform: scale(0.1);
  }

  10% {
    transform: scale(0.2);
  }

  20% {
    transform: scale(0.3);
  }

  30% {
    transform: scale(0.4);
  }

  40% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(0.6);
  }

  60% {
    transform: scale(0.7);
  }

  80% {
    transform: scale(0.8);
  }

  90% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    display: block;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
