.personal {
  margin: 0 auto;
  max-width: 1120px;
  width: 100%;
  text-align: left;

  &__content {
    margin-top: 16px;
    padding: 48px;
    border-radius: 16px;
    background-color: #fff;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    gap: 32px 40px;
    margin-top: 40px;
  }

  &__photo {
    width: 213px;
    height: 213px;

    & .photoUpload {
      width: 100%;
      height: 100%;

      &::after {
        content: none;
      }

      & .customPlaceHolder {
        &::after {
          content: none;
        }
      }

      & img {
        object-fit: cover;
      }

      & .choose {
        padding-inline: 0;
      }
    }
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 2px 14px;

    & + & {
      margin-top: 2px;
    }
  }

  &__info-label {
    display: block;
    min-width: 170px;
    font-size: 18px;
    line-height: 27px;
  }

  &__info-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
