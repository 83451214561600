@import '_mixin';
@import '_slices';

.Calculator {
  margin: -20px;
  ::-webkit-scrollbar {
    display: none;
  }

  & .container {
    padding-top: 10px;
    height: calc(100vh - 110px);
    max-width: 1120px;
    margin: 0 auto;
    overflow-y: auto;

    & .client_info {
      @include flex_align_justify(center, normal);
      margin-bottom: 15px;

      & div {
        padding: 8px 16px;
        border-radius: 10px;
        height: 65px;
        background-color: white;
        margin-right: 16px;
        text-align: left;

        & h2 {
          @include size_weight_height_color(16px, 600, 150%, #111827);
          max-width: 220px;
          padding-right: 20px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 5px;
        }

        & p {
          @include size_weight_height_color(14px, 400, 140%, #687588);
        }

        &:first-child {
          @include clientInfoAfter(
            250px,
            '../media/icons/edit_pen_icon.png',
            20px,
            20px
          );
        }

        &:nth-child(2n) {
          @include clientInfoAfter(
            225px,
            '../media/icons/phone.png',
            20px,
            20px
          );
        }
      }
    }

    & .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    & .results_container {
      display: flex;
      width: 544px;
      min-height: 372px;
      padding: 24px;
      flex-direction: column;
      align-items: start;
      gap: 24px;
      border-radius: 10px;
      background: #fff;

      & h2 {
        color: #111827;
        width: 100%;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        margin-bottom: 20px;
        text-align: left;
      }

      & p {
        color: #111827;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-align: left;
        line-height: 150%;
      }

      & .Collapse_header {
        display: flex;
        align-items: center;
        & .image_wrapper {
          width: 32px;
          height: 32px;
          stroke-width: 1px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & p {
          margin-left: 8px;
          height: auto;
        }
      }

      & .ant-collapse {
        border: none;
        background: #f5f9ff;
      }

      & .ant-collapse-header {
        display: flex;
        align-items: center;
      }

      & .ant-collapse-content {
        border: none;

        & .main_info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          padding-bottom: 16px;
          border-bottom: 1px solid #e9eaec;

          & h2 {
            margin: 0;
            color: #111827;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }

          & p {
            color: #111827;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;

            & span {
              color: #ff2e1f;
            }
          }
        }

        & .other_info_container {
          & div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            & p {
              color: #687588;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              white-space: nowrap;
            }

            & h2 {
              margin: 0;
              text-align: right;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 140%;
              color: #111827;
            }
          }
        }
      }

      & .ant-btn {
        width: 100%;
        color: #111827;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        margin-top: 16px;
        padding: 8px 24px;
        border-radius: 10px;
        border: 1px solid #cbd5e0;
        height: 36px;
        display: flex;
        justify-content: center;

        & img {
          margin-right: 8px;
        }
      }

      & .ant-collapse-item {
        margin: 16px auto !important;
        width: 500px;
        border-radius: 8px;
        border: none;
      }

      & .ant-collapse-content-box {
        border-radius: 0 0 8px 8px;

        display: flex;
        flex-direction: column;

        & button {
          background: #fafafa;
        }
      }
    }

    & .product_info {
      text-align: left;
      background-color: white;
      height: fit-content;
      width: 50%;
      border-radius: 10px;
      padding: 24px;

      @extend %orderStep_firstStep;
      @extend %orderStep_secondStep;
      @extend %orderStep_thirdStep;

      & .continue_btn {
        @include size_weight_height_color(14px, 600, normal, white);
        width: 100%;
        margin-top: 24px;
        border-radius: 10px;
        border: none;
        //padding: 16px 24px;
        background-color: #1f9aff;

        &:disabled {
          background-color: #f1f2f4;
        }
      }
    }
  }
}
