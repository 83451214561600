@import '_mixin';
@import '_animation';

.Layout {
  display: flex;
  height: 100vh;

  & .city_info {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #a0aec0;
  }

  & .side-bar {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #1f2937;
    padding: 24px 32px;
    transition: width 0.6s;

    & .logo_box {
      @include flex_align_justify(center, space-between);

      & button {
        background: none;
        border: none;
      }
    }

    & .navigation_box {
      & .tooltip {
        display: none;
      }
      & .ant-menu {
        //background-color: rgba(0,0,0,0);
        & .ant-menu-submenu {
          & .ant-menu-sub {
            //background-color: rgba(0,0,0,0);
            background: #1f2937;
          }
        }
      }
      @include navigation_tmp {
        &:first-child {
          @include flex_align_justify(center, space-between);
          flex-direction: row-reverse;
          color: #fff;
          gap: 20px;
          align-self: stretch;
          margin: 24px 0;
          padding: 16px 20px;
          border-radius: 10px;
          background-color: #ff2e1f;
          box-shadow: 0px 10px 30px 0px rgba(255, 46, 31, 0.2);

          & img {
            margin-right: 0;
            filter: invert(10%) sepia(100%) saturate(100%) hue-rotate(269deg)
              brightness(103%) contrast(101%);
          }
        }
      }
    }
    & .admin_nav_box {
      & .tooltip {
        display: none;
      }

      @include navigation_tmp {
        &:first-child {
          @include flex_align_justify(center, space-between);
          flex-direction: row-reverse;
          color: #fff;
          gap: 20px;
          align-self: stretch;
          margin: 24px 0;
          padding: 16px 20px;
          border-radius: 10px;
          background-color: #ff2e1f;
          box-shadow: 0px 10px 30px 0px rgba(255, 46, 31, 0.2);

          & img {
            margin-right: 0;
            filter: invert(10%) sepia(100%) saturate(100%) hue-rotate(269deg)
              brightness(103%) contrast(101%);
          }
        }
      }
    }

    & .support_setting_box {
      @include navigation_tmp;
      position: absolute;
      bottom: 24px;
    }

    & button {
      cursor: pointer;
    }

    &.expanded {
      min-height: 700px;

      @include expanded-state;
    }

    &.collapsed {
      @include collapsed-state;
    }
  }

  & .layout-header,
  & header {
    height: 90px;
    border-bottom: 1px solid #e9eaec;

    &__container {
      display: flex;
      align-items: center;
      padding: 0 24px;
      width: min(1440px, 100%);
      height: 100%;
      margin: 0 auto;
    }

    & .main-menu {
      flex: 1;
      display: flex;
      margin-right: 40px;

      &__link {
        display: block;
        position: relative;
        padding: 10px 10px;
        border-radius: 6px;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        line-height: 22.4px;
        color: black;
        transition: all 0.1s linear;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          width: 0;
          height: 1px;
          bottom: 9px;
          transition: width 0.2s linear, left 0.2s linear;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &--active {
          &::after {
            content: '';
            position: absolute;
            left: 10px;
            width: calc(100% - 20px);
            background-color: #1f9aff;
          }
        }
      }
    }

    & .pagination {
      @include flex_align_justify(center, flex-start);
      gap: 24px;

      & p {
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        color: #a0aec0;
      }

      & .lang {
        @include flex_align_justify(center, center);
        @include size_weight_height_color(10px, 700, 140%, #687588);
        width: 34px;
        height: 32px;
        border-radius: 6px;
        background-color: #e9eaec;
        cursor: pointer;
      }

      & .user_name {
        @include size_weight_height_color(14px, 500, 140%, #111827);

        &__dealer {
          font-size: 12px;
          color: #687588;
        }
      }

      & .bell_btn {
        border: none;
        background: none;
        cursor: pointer;
      }
    }

    & h2 {
      @include size_weight_height_color(20px, 700, 140%, #111827);
    }
  }

  & .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  & main {
    min-height: 650px;
    text-align: center;
    flex: 1;
    background-color: #f1f2f4;
    padding: 20px;
    overflow-y: auto;
  }
}
