
@import '_mixin';
.container {
  padding-top: 10px;
  //height: calc(100vh - 110px);
  //width: 1015px;
  max-width: 814px;
  //width: 80%;
  margin: 0 auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & .client_info {
    width: 100%;
    //width: 1112px;
    box-sizing: border-box;
    margin: 0 auto 15px;
    @include flex_align_justify(center, flex-start);
    //margin-bottom: 15px;

    & div {
      padding: 8px 16px;
      border-radius: 10px;
      height: 65px;
      background-color: white;
      margin-right: 16px;
      text-align: left;

      & h2 {
        @include size_weight_height_color(16px, 600, 150%, #111827);
        //max-width: 365px;
        width: 100%;
        padding-right: 25px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 5px;
      }

      & p {
        @include size_weight_height_color(14px, 400, 140%, #687588);
      }

      &:first-child {
        max-width: 45%;
        @include clientInfoAfter(
                        auto,
                        '../media/icons/edit_pen_icon.png',
                        20px,
                        20px
        );
      }

      &:nth-child(2n) {
        @include clientInfoAfter(
                        225px,
                        '../media/icons/phone.png',
                        20px,
                        20px
        );
      }

      &:last-child {
        @include clientInfoAfter(
                        178px,
                        '../media/icons/question-circle-outlined-lighter.png',
                        20px,
                        20px
        );

        & p {
          cursor: pointer;
          position: relative;

          //&::before {
          //  content: '';
          //  display: block;
          //  position: absolute;
          //  width: 145px;
          //  height: 1px;
          //  right: 0px;
          //  top: 18px;
          //  border-bottom: 1px dotted black;
          //}
        }
      }
    }
  }
}