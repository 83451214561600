@import '_mixin';
@import '_slices';

.CalculatorForm {
  //display: flex;
  //align-items: normal;
  //justify-content: space-between;

  & h2 {
    color: #111827;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
  }

  & .selects_box {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 245px));
    grid-template-rows: repeat(4, 56px);
    gap: 16px;
  }

  & .ant-btn {
    margin-top: 14px;
    width: 100%;
    height: 48px;
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #a0aec0;

    & span {
      font-family: 'IBM Plex Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }
  }
}
