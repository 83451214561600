.auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;

  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 32px;
    width: calc(480px + 64px);
    height: 100%;
    font-family: 'IBM Plex Sans', sans-serif;

    & > * {
      width: 100%;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-top: auto;
  }

  &__title {
    margin-top: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
  }

  &__form {
    margin-top: 32px;
  }

  &__rights {
    margin-top: auto;
  }

  &__banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 510px;
    height: 100dvh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
  }

  &__banner-info {
    position: relative;
    z-index: 10;
    margin-top: auto;
    padding-bottom: 100px;
    padding-left: 48px;
    color: #1f9aff;
  }

  &__banner-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 57.7px;
  }

  &__banner-description {
    margin-top: 20px;
    font-size: 20px;
  }

  @media (max-width: 1200px) {
    &__banner {
      width: 40%;
    }

    &__banner-info {
      padding: 0 16px;
      padding-bottom: 64px;
    }

    &__banner-title {
      font-size: 32px;
      line-height: 41.7px;
    }

    &__banner-description {
      margin-top: 12px;
      font-size: 16px;
    }
  }

  @media (max-width: 900px) {
    &__content {
      padding: 32px 16px;
    }

    &__banner {
      display: none;
    }
  }
}
