.render-bank-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #e9eaec;
  border-radius: 50%;

  &__status {
    display: block;
    position: absolute;
    right: -4px;
    bottom: -3px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
}
