.request-info {
  display: flex;
  align-items: center;
  gap: 24px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #f8f8f8;

    & > svg path {
      fill: #111827;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 31.2px;
  }

  &__description {
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.2px;
  }
}
