.Reports {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  width: 1120px;
  align-self: stretch;
  margin: 0 auto;

  & h2 {
    color: #111827;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  & .Reports_templates_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 340px);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
}
.radio-btn {
  margin-right: 20px;
  border-radius: 10px;
  border: 1px solid #cbd5e0 !important;
}
.ant-radio-button-wrapper-checked {
  background-color: #1f2937 !important;
  border: none !important;
  span {
    color: white;
  }
}
.ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}