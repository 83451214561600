.form {
  margin: 0 auto;
}

h1 {
  font-weight: 100;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

form p {
  font-size: 12px;
  color: #bf1650;
}

form p::before {
  display: inline;
  content: '';
}

form input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(223, 218, 218);
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

form label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 200;
}

form input:disabled {
  opacity: 0.4;
}
.verify-input-field {
  /*border: 1px solid #656567;*/
}
.verify-input-field::-webkit-outer-spin-button,
.verify-input-field::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
