.BankSubPrintItem {
  height: 24px;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  & p {
    color: #111827;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
