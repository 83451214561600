.client_info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: max-content;
  box-sizing: border-box;
  font-size: 12px;

  &__go-back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 5px;
    color: #1f9aff;
    background-color: white;
  }

  &__id-card {
    display: flex;
    align-items: center;
    gap: 9px;
    text-align: left;
    cursor: pointer;

    &.client_info__id-card h2 {
      padding-right: 0;
      text-overflow: initial;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 16px;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    text-align: left;
  }

  &__block-title {
    position: relative;
    padding-left: 18px;
    margin: 10px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 9px;
      height: 16px;
      border-radius: 3px;
      background-color: var(--block-color);
    }
  }

  & p {
    font-size: 14px;
    line-height: 140%;
    color: #687588;
  }

  &__values {
    margin-top: 2px;
    padding-top: 4px;
    border-top: 1px solid #e5e5e5;
    line-height: 21px;

    & > b {
      display: block;
      font-weight: 600;
      color: #111827;
    }
  }

  & h2 {
    width: 100%;
    max-width: 200px;
    padding-right: 25px;
    display: block;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }

  &__normal-text {
    font-weight: 400 !important;
  }

  & .client-info__status {
    padding: 2px 10px;
    border-radius: 4px;
    width: max-content;
    font-size: 10px;
    line-height: 700;
    line-height: 16px;
    color: white;
  }

  & .client_info__phone {
    position: relative;
    padding-right: 25px;
    font-size: 14px;
    line-height: 21px;
    color: #111827;
  }

  & .client_info__icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    cursor: pointer;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
}
