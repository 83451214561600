.offers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;

  @media (max-width: 1440px) {
    gap: 32px;
  }

  @media (max-width: 1240px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
