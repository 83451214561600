@import '_mixin';

.customPlaceHolder {
  height: 55px;
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  & .ant-select {
    width: 100%;

    & span {
      color: #111827;
      font-family: 'IBM Plex Sans';
    }
  }

  & .ant-picker {
    width: 100%;
  }

  & label {
    @include size_weight_height_color(12px, 400, 160%, #687588);
    position: absolute;
    width: 80%;
    top: 30%;
    left: 10px;
    transition: 0.1s ease;
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    &::after {
      content: '*';
      color: #e03137;
      margin-left: 5px;
    }
  }
}

.focused {
  & label {
    top: 2px;
  }

  & .ant-select-selection-item {
    margin-top: 15px;
  }

  & .ant-input {
    padding-top: 20px;
  }
}
