.graphic {
  padding: 24px;
  border-radius: 16px;
  background-color: white;

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__chart {
    position: relative;
    margin: 0 auto;
    margin-top: 24px;
    width: max-content;
    border-radius: 50%;
    background-color: #f9f9f9;
  }

  &__chart-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: white;
    font-size: 14px;
    line-height: 21px;

    & > b {
      white-space: nowrap;
      font-size: 32px;
      line-height: 120%;
    }
  }

  &__info {
    margin-top: 24px;
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 2px 12px;
    padding: 12px 0;
    border-top: 1px solid #f1f2f4;
  }

  &__item-circle {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: transform 0.1s ease;
  }

  &__item-title {
    color: #687588;
  }

  &__item-value {
    margin-left: auto;
  }
}
