.custom_checkbox_root {
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  width: 24px;
  height: 24px;
}

.custom_checkbox_root input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  border-radius: 6px;
  top: 5px;
  left: 0;
  width: 24px;
  height: 24px;
  transition: 0.3s;
  background-color: #eee;
}

.custom_checkbox_root:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom_checkbox_root input:checked ~ .checkmark {
  background-color: #1f9aff;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.custom_checkbox_root input:checked ~ .checkmark:after {
  display: block;
}

.custom_checkbox_root .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
