@import '_mixin';

.Leasing {
  width: 1112px;
  min-height: 600px;
  border-radius: 16px;
  background-color: #fff;
  padding: 20px 25px;
  display: inline-flex;
  flex-direction: column;

  & .header {
    @include flex_align_justify(center, space-between);
    height: 50px;
    margin-bottom: 10px;

    & .title {
      text-align: left;
      & h2 {
        @include size_weight_height_color(18px, 700, 140%, #111827);
      }

      & p {
        @include size_weight_height_color(14px, 400, 140%, #687588);
      }
    }

    & .pagination {
      @include flex_align_justify(center, flex-end);
      width: 370px;

      & button:first-child {
        @include flex_align_justify(center, space-between);
        height: 56px;
        width: 177px;
        padding: 16px 24px;
        border-radius: 10px;
        border: 1px solid #687588;
        background-color: white;

        & p {
          @include size_weight_height_color(16px, 600, 150%, #111827);
          margin-left: 10px;
        }

        &:hover {
          border: none;
        }
      }

      & button:last-child {
        @include flex_align_justify(center, space-between);
        height: 56px;
        width: 182px;
        padding: 16px 24px;
        border-radius: 10px;
        border: none;
        background-color: #1f9aff;

        & p {
          @include size_weight_height_color(16px, 600, 150%, #fff);
          position: relative;

          &::after {
            content: '+';
            font-size: 25px;
            font-weight: 400;
            position: absolute;
            right: -25px;
          }
        }

        &:hover {
          background: #111827;
        }
      }
    }
  }

  & .table_editor {
    @include flex_align_justify(flex-start, space-between);
    margin: 5px 0;
    height: 50px;
    width: 100%;

    & .ant-input-lg {
      border-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      //height: 50px;
    }

    & .ant-input-group-addon {
      & button {
        border-left: none;
        //height: 50px;

        &:hover {
          border-color: #111827;
        }
      }
    }

    & .ant-input-search-button {
      border-end-end-radius: 10px !important;
      border-start-end-radius: 10px !important;
    }

    & .ant-input-group-wrapper {
      height: 100%;

      & input:hover {
        border-color: #111827;
      }
    }

    & .ant-btn-icon {
      & span {
        color: #111827 !important;
      }
    }

    & .ant-select {
      //height: 50px;

      &:not(.ant-select-disabled):not(.ant-select-customize-input):not(
          .ant-pagination-size-changer
        ):hover
        .ant-select-selector {
        border-color: #111827 !important;
      }

      & .ant-select-selector {
        border-radius: 10px;
      }

      & span {
        color: #111827;
      }

      & .ant-select-selection-item {
        text-align: left;
      }

      & .ant-select-selection-placeholder {
        text-align: left;
      }
    }

    & .filter_btn {
      @include flex_align_justify(center, center);
      background: none;
      border-radius: 10px;
      border: 1px solid #e9eaec;
      //height: 100%;
      width: 50px;
    }
  }

  & .table {
    flex: 1 0 0;
    margin-top: 10px;

    & .ant-pagination {
      margin-top: 30px;
      margin-bottom: 0;
      & .ant-select-selector {
        border: 1px solid #111827;
      }
    }

    & .ant-pagination-item {
      border-color: #111827;

      & a {
        color: #111827;
      }
    }

    & .ant-table-thead {
      height: 56px;

      & .ant-table-column-sorter-down {
        font-size: 10px;
      }

      & .ant-table-cell {
        @include size_weight_height_color(12px, 700, 140%, #687588);
        padding: 16px !important;
      }
      & .ant-table-column-title {
        @include size_weight_height_color(12px, 700, 140%, #687588);
      }
    }

    & .ant-table-body {
      //max-height: calc(100vh - 365px) !important;
      // height: 500px !important;
      // max-height: 500px !important;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
        /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(131, 131, 131, 0.21);
        /* color of the scroll thumb */
        border-radius: 20px;
      }
    }

    & .ant-table-cell {
      padding: 0 8px !important;
      height: 60px;
    }
  }
}
.auto-cred-table-row {
  cursor: pointer;
}
