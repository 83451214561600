.main-page {
  margin: 0 auto;
  padding-bottom: 32px;
  width: min(1112px, calc(100% - 30px));
  text-align: left;

  &__filters-card {
    padding: 24px 25px;
    border-radius: 16px;
    background-color: white;
  }

  &__filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    gap: 16px;
    margin-top: 24px;
  }

  &__filters-item {
    height: auto;
  }

  &__select {
    & .ant-select {
      &-selector {
        border-radius: 10px;
        border-color: #e9eaec !important;
      }

      &-arrow {
        transform: scale(1.3, 0.9);
        color: #111827;
      }
    }
  }

  &__date-filter {
    padding: 16px;
    border-radius: 10px;
    border-color: #e9eaec;

    & .ant-picker {
      justify-content: space-between;
      text-align: center;

      &-suffix {
        margin-left: 8px;
        color: #111827;
      }

      &-range-separator {
        padding: 0 4px;

        & > span {
          display: block;
          height: 1px;
          width: 4px;
          background-color: #111827;
        }

        & svg {
          display: none;
        }
      }
    }
  }

  &__request-info {
    display: flex;
    gap: 24px;
    margin-top: 16px;
  }

  &__request-item {
    flex: 1;
    padding-block: 24px;

    & > * + * {
      margin-top: 48px;
    }
  }

  &__separator {
    width: 1px;
    background-color: #f1f2f4;
  }

  &__graphics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 24px;
    margin-top: 32px;
  }

  &__table-card {
    margin-top: 32px;
    padding: 24px;
    border-radius: 16px;
    background-color: white;
  }

  &__table {
    margin-top: 24px;
  }

  &__table-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__table-description {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
    color: #687588;
  }
}
